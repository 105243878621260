export class SubMenu {
    idFirebase: string;
    title: string;
    url: string;
    icon: string;
    color: string;
    acesso: string;

    constructor(id: string, titulo: string, url: string, icone: string, cor: string, acesso: string) {
        this.idFirebase = id;
        this.title = titulo;
        this.url = url;
        this.icon = icone;
        this.color = cor;
        this.acesso = acesso;
    }
}