import { ExtratoHorasDatas } from "./extrato-horas-datas";

export class ExtratoHoras {
    totHrExtra: string;
    totHrOciosa: string;
    datas: Array<ExtratoHorasDatas>;

    constructor(totHrExtra: string, totHrOciosa: string, datas: Array<ExtratoHorasDatas>) {
        this.totHrExtra = totHrExtra;
        this.totHrOciosa = totHrOciosa;
        this.datas = datas;
    }

    static adapts(obj: any): ExtratoHoras {
        let totHrExtra = obj.TOT_HR_EXTRA;
        let totHrOciosa = obj.TOT_HR_OCIOSA;
        let datas = this.adaptDatasExtrato(obj.Datas);

        return new ExtratoHoras(totHrExtra, totHrOciosa, datas);
    }

    static adaptDatasExtrato(data): Array<ExtratoHorasDatas> {
        let list = new Array<ExtratoHorasDatas>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpData(l));
            });
        }
        return list;
    }

    static adatpData(obj: any): ExtratoHorasDatas {
        let seq = obj.SEQ;
        let data = obj.DATA;
        let hrExtra = obj.HR_EXTRA;
        let hrOciosa = obj.HR_OCIOSA;    


        return new ExtratoHorasDatas(seq, data, hrExtra, hrOciosa);
    }









}