import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-img-rec',
  templateUrl: './img-rec.html',
  styleUrls: ['./img-rec.scss'],
})
export class ImgRecPage implements OnInit {
  imagem: string;

  constructor(private modalController: ModalController, private navParams: NavParams) { }

  ngOnInit() {
    this.imagem = this.navParams.get('img');
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
