import { SaldoBcoHrsDet } from "./saldo-bh-detail";

export class SaldoBcoHrs {
    idFirebase: string;
    registro: string;
    totSaldo: string;
    totHrExtra: string;
    totHrOci: string;
    meses: Array<SaldoBcoHrsDet>;

    constructor (id: string, registro: string,  totSaldo: string, totHrExtra: string, totHrOci: string, meses: Array<SaldoBcoHrsDet>) {
        this.idFirebase = id;
        this.registro = registro;
        this.totSaldo = totSaldo;
        this.totHrExtra = totHrExtra;
        this.totHrOci = totHrOci;
        this.meses = meses;
    }

    static adapts(obj: any): SaldoBcoHrs {
        let idFirebase = obj.key;
        let registro = obj.REGISTRO;
        let totSaldo = obj.SALDO;
        let totHrExtra = obj.TOT_HR_EXTRA;
        let totHrOci = obj.TOT_HR_OCI;
        let meses = this.adptSaldos(obj.Meses);    

        return new SaldoBcoHrs(idFirebase, registro, totSaldo, totHrExtra, totHrOci, meses);
    }

    static adptSaldos(data): Array<SaldoBcoHrsDet> {
        let list = new Array<SaldoBcoHrsDet>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpSaldo(l));
            });
        }
        return list;
    }

    static adatpSaldo(obj: any): SaldoBcoHrsDet {
        let comp = obj.MES + '/' + obj.ANO;
        let hrExtra = '';
        let hrOci = '';

        if (obj.HR_EXTRA != '00:00') {
            hrExtra = obj.HR_EXTRA;
        }

        
        if (obj.HR_OCI != '00:00') {
            hrOci = obj.HR_OCI;
        }
     
        return new SaldoBcoHrsDet(comp, hrExtra, hrOci);
    }

}