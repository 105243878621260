export const environment = {
   production: false,
   config: {
      empresa: {
         emp: 7,
         nroEmp: "60",
         dominioEmp: '@transcal.com.br',
         emailEmp: 'transcal@transcal.com.br',
         termoPrivacy: 'https://ngsservicos.com/aplicativos/NgFuncionario/transcal/arquivos/transcalinfo.html',
         htmlVersao: 'https://ngsservicos.com/aplicativos/NgFuncionario/transcal/versoes/news',
         htmlInfoMedalha: '',
         versaoApp: '1.2.3',
         nomeApp: 'TRANSCAL Info',
         versaoMenu: 'V1',
         nomeEmpresa: 'Transcal',
         baseUrl: ''
      },
      firebase: {
         apiKey: "AIzaSyDNNWGMo-dgIs4yQWBqxHdwKrJzV_Qi1MA",
         authDomain: "apptranscal.firebaseapp.com",
         databaseURL: "https://apptranscal.firebaseio.com",
         projectId: "apptranscal",
         storageBucket: "apptranscal.appspot.com",
         messagingSenderId: "1082718386541",
         appId: "1:1082718386541:web:2db41477dbfad2203b4264"
      },
      mensagens: {
         msgRecSenha: 'E-mail não encontrado! Procure a empresa para atualizar o seu e-mail.',
         msgRecSenha2: 'Você receberá em alguns minutos um e-mail com a nova senha de acesso. Qualquer d�vida entrar em contato com a empresa.'
      },
      style: {
         logoEmpresa: 'https://ngsservicos.com/aplicativos/NgFuncionario/transcal/imagens/logoEmp.png',
         logoEmpresa2: 'https://ngsservicos.com/aplicativos/NgFuncionario/transcal/imagens/logoEmp.png',
         iconeApp: 'https://ngsservicos.com/aplicativos/NgFuncionario/transcal/imagens/icon.png',
         iconeApp2: '',
         corEmpresa: 'color17',
         corMenu: 'color21',
         corMenu1: 'color22',
         corMenu2: 'color21',
         varCSS: 'logoTranscal',
         varCSS2: '',
         varCSS3: '',
         varCSS4: 'img-menu-transcal'
      }
   }
};
