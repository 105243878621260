export class ExtBhResumo {
    conta: string;
    dConta: string;
    horas: string;
    seq: string;

    constructor(conta: string, dConta: string, horas: string, seq: string) {
        this.conta = conta;
        this.dConta = dConta;
        this.horas = horas;
        this.seq = seq;

    }
}