export class Solicitacao {
    idFirebase: string;
    assunto: string;
    dataf: string;
    datai: string;
    descricao: string;
    dtAb: string;
    anoFerias: string;
    mesFerias: string;
    linha: string;
    localAb: string;
    retorno: string;
    solicitante: string;
    tabela: string;
    lb1: string;
    lb2: string;
    lbT1: string;
    lbT2: string;
    clReturn: string;

	// Método construtor do objeto
    constructor(id: string, assunto: string, dataf: string, datai: string, descricao: string, dtAb: string, anoFerias: string, mesFerias: string, linha: string,
        localAb: string, retorno: string, solicitante: string, tabela: string, lb1: string, lb2: string, lbT1: string, lbT2: string, clReturn: string) {

		this.idFirebase = id;
        this.assunto = assunto;
        this.dataf = dataf;
        this.datai = datai;
        this.descricao = descricao;
        this.dtAb = dtAb;
        this.anoFerias = anoFerias;
        this.mesFerias = mesFerias;
        this.linha = linha;
        this.localAb = localAb;
        this.retorno = retorno;       
        this.solicitante = solicitante;
        this.tabela = tabela;
        this.lb1 = lb1;
        this.lb2 = lb2;
        this.lbT1 = lbT1;
        this.lbT2 = lbT2;     
        this.clReturn = clReturn;
	}

	// Adaptando o objeto retornado pelo Firebase para o nosso Model
	static adapts(obj: any) : Solicitacao {
        let idFirebase = obj.key;
        let assunto = obj.Assunto;
        let dataf = obj.DataF;
        let datai = obj.DataI;
        let descricao = obj.Descricao;
        let dtAb = obj.DtAb;
        let anoFerias = obj.FeriasAno;
        let mesFerias = obj.FeriasMes;
        let linha = obj.Linha;
        let localAb = obj.LocalAb;
        let retorno = obj.Retorno;              
        let solicitante = obj.Solicitante;
        let tabela = obj.Tabela;
        let lb1 = ' ';
        let lb2 = ' ';
        let lbT1 = ' ';
        let lbT2 = ' ';
        let clReturn = 'black';

        if (obj.Assunto == '1') {
            lb1 = 'Período: ';
            lbT1 = this.getDtConve(obj.DataI) + ' a ' + this.getDtConve(obj.DataF);
            lb2 = ' ';
            lbT2 = ' ';
        } else if (obj.Assunto == '2') {
            lb1 = 'Linha: ';
            lbT1 = obj.Linha;
            lb2 = 'Tabela: ';
            lbT2 = obj.Tabela;
        } else if (obj.Assunto == '3') {
            lb1 = 'Mês: ';
            lbT1 = obj.FeriasMes;
            lb2 = 'Ano: ';
            lbT2 = obj.FeriasAno;
        };    
        
        if (obj.Retorno == 'Pendente') {
            clReturn = 'red';
        } else {
            clReturn = 'blue';
        }

		return new Solicitacao(idFirebase, assunto, dataf, datai, descricao, dtAb, anoFerias, mesFerias, linha, localAb, retorno, solicitante, tabela, lb1, lb2, lbT1, lbT2, clReturn);
    }
    
    static getDtConve(dt: string) {
		let data = new Date(dt);
		let hoje = ('0' + data.getUTCDate()).slice(-2) + '/' + ('0' + (data.getUTCMonth() + 1)).slice(-2) + '/' + data.getUTCFullYear();
		return hoje;
	}
}