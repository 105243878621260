import { Eventos } from "./eventos";

export class DtAgenda {
    data: string;
    dia: number
    diaSemana: string;
    diaSemanaExt: string;
    dataCompleta: string;
    eventos: Array<Eventos>;

    constructor(data: string, dia: number, diaSemana: string, diaSemanaExt: string, dataCompleta: string, eventos: Array<Eventos>) {
        this.data = data;
        this.dia = dia;
        this.diaSemana = diaSemana;
        this.diaSemanaExt = diaSemanaExt;
        this.dataCompleta = dataCompleta;
        this.eventos = eventos;
    }

    static adaptEventos(data): Array<Eventos> {
        let list = new Array<Eventos>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpEvento(l));
            });
        }
        return list;
    }


    static adatpEvento(obj: any): Eventos {
        let idEvento = obj.IDEvento;
        let dtEvento = obj.Data;
        let seqEvento = obj.SeqEvento;
        let tpEvento = obj.Tipo;
        let assunto = obj.Assunto;
        let diaInteiro = obj.DiaInteiro;
        let hrIni = obj.HoraInicio;
        let hrFim = obj.HoraFinal;
        let obs = obj.Obs;

        return new Eventos(idEvento, dtEvento, seqEvento, tpEvento, assunto, diaInteiro, hrIni, hrFim, obs);
    }




}

