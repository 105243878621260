export class Rendimentos {
    idFirebase: string;
    anoRef: string;
    urlArq: string;

    constructor(id: string, ano: string, url: string) {
        this.idFirebase = id;
        this.anoRef = ano;
        this.urlArq = url;
    }

    static adapts(obj: any): Rendimentos {
        let idFirebase = obj.key;
        let anoRef = obj.ANO_REF;
        let urlArq = obj.URL_ARQ;

        return new Rendimentos (idFirebase, anoRef, urlArq);
    }

}