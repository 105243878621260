import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.html',
  styleUrls: ['./alert-modal.scss'],
})
export class AlertModalPage implements OnInit {
  isTitulo: string;
  isSubtitulo: string;
  isMensagem: string;
  isNumero: string;
  isTipo: string;
  fcomnro: boolean = false;

  constructor(private navParams: NavParams, private modalController: ModalController, public domSanitizer: DomSanitizer, private router: Router) { }

  ngOnInit() {
    this.isTitulo = this.navParams.get('titulo');
    this.isSubtitulo = this.navParams.get('subtitulo');
    this.isMensagem = this.navParams.get('mensagem');
    this.isTipo = this.navParams.get('tipo');
    this.isNumero = this.navParams.get('numero');
    if (this.isNumero != '') { this.fcomnro = !this.fcomnro };
  }

  closeModal() {
    this.modalController.dismiss();
  }

  onCloseCustom() {
    if (this.isTipo === 'H') {
      this.closeModal();
      this.router.navigate(['/home']);
    } else if (this.isTipo === 'N') {
      this.closeModal();
    }
  }

  sanitize(html) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
