export class RedeSocial {
    seq: string
    rede_social: string;
    icon: string;
    url: string;
    title: string;
    fone: string;
    nivel: string;


    constructor(seq: string, rede_social: string, icon: string, url: string, title: string, fone: string, nivel: string) {
        this.seq = seq;
        this.rede_social = rede_social;
        this.icon = icon;
        this.url = url;
        this.title = title;
        this.fone = fone;
        this.nivel = nivel;
    }

    static adapts(obj: any): RedeSocial {
        let seq = obj.SEQ;
        let rede_social = obj.REDE_SOCIAL;
        let icon = obj.ICONE;
        let url = '';

        if (obj.REDE_SOCIAL === '7') {
            url = 'https://api.whatsapp.com/send?phone=' + obj.FONE;
        } else {
            url = obj.LINK_REDE;
        }

        let title = obj.TITLE;
        let fone = obj.FONE;
        let nivel = obj.DNIVEL;     

        return new RedeSocial(seq, rede_social, icon, url, title, fone, nivel);
    }

}