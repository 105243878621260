import { Component, OnInit, ViewChild } from '@angular/core';
import { IonReorderGroup, ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { Atalhos } from 'src/models/atalhos';
import { Funcionario } from 'src/models/funcionario';
import { FirebaseProvider } from 'src/provider/firebase/firebase';
import { Menu } from 'src/models/menu';
import { DmgeralService } from 'src/provider/dmgeral/dmgeral';

@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.html',
  styleUrls: ['./favoritos.scss'],
})
export class FavoritosPage implements OnInit {
  funcionarioLogado: Funcionario;
  favoritos: Array<Atalhos> = []
  menus: Array<Menu> = [];
  listMenu: Array<Menu> = [];
  isDisabled: boolean = false;

  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;

  constructor(private firebase: FirebaseProvider, private dmgeral: DmgeralService, private modalController: ModalController) { }

  ngOnInit() {
    this.firebase.getStorageUser().pipe(take(1)).subscribe(u => this.funcionarioLogado = u);
    this.buscaMenu();
  }

  closeModal() {
    this.modalController.dismiss(true);;
  }

  buscaMenu() {
    this.firebase.vMenu.subscribe(menu => {
      this.menus = menu.filter((m) => m.atalho != 'S' && m.Menu_Filho == undefined);
    });
    this.firebase.isFavoritos.subscribe(favorito => {
      this.favoritos = favorito;
    });

    this.menus.forEach(m => {
      let aux: boolean = false;
      this.favoritos.forEach(f => {
        if (m.url === f.url) {
          aux = true
        }
      });
      if (aux == false) {
        this.listMenu.push(m);
      }
    });

    this.listMenu.forEach(l => {
      if (l.atalho === 'S') {
        l.atalho = 'true'
      } else {
        l.atalho = 'false'
      }
    });
  }

  onCheck(f: Atalhos, index) {
    if (f != null && f != undefined) {
      this.favoritos.splice(index, 1);
      this.menus.forEach(m => {
        if (m.url === f.url) {
          m.atalho = 'false'
          this.listMenu.push(m);
        }
      })
      this.listMenu.sort((a, b) => a.title.localeCompare(b.title));
    }
  }

  onCheckMn(m: Menu, index) {
    if (m != null && m != undefined) {
      this.listMenu.splice(index, 1)
      this.favoritos.push({ icon: m.icon, title: m.title, url: m.url, comBadge: 'N', comPadrao: 'N', atalho: 'true' })
    }
  }

  onSave() {
    this.dmgeral.abreLoading();
    this.favoritos.sort((a, b) => a.title.localeCompare(b.title));
    this.firebase.setFavorito(this.funcionarioLogado.idFirebase, this.favoritos).then(res => {
      if (res === 'OK') {
        this.dmgeral.fechaLoading();
        this.closeModal();
        this.dmgeral.openTost('Favoritos salvos com sucesso!', 2)
      }
    });
  }

}
