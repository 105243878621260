export class SaldoBcoHrsDet {
    comp: string;
    hrExtra: string;
    hrOci: string;

    constructor(comp: string, hrExtra: string, hrOci: string) {
      this.comp = comp;
      this.hrExtra = hrExtra;
      this.hrOci = hrOci;

    }
}