import { Component, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Menu } from 'src/models/menu';
import { environment } from 'src/environments/environment';
import { FirebaseProvider } from 'src/provider/firebase/firebase';
import { Router } from '@angular/router';
import { Funcionario } from 'src/models/funcionario';
import { ImgRecPage } from 'src/pages/img-rec/img-rec';

const iosoption: InAppBrowserOptions = {
  zoom: 'no',
  location: 'yes',
  toolbar: 'yes',
  clearcache: 'yes',
  clearsessioncache: 'yes',
  disallowoverscroll: 'yes',
  enableViewportScale: 'yes'
}


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public pages: Array<Menu>;
  funcionarioLogado: Funcionario;
  public selectedIndex;
  public selectedSubIndex;
  public vIndex: number;
  isEmpNgs: number = environment.config.empresa.emp;
  aux: any;
  vColorMenu2 = environment.config.style.corMenu2;
  vComPerfil: string;
  comFotoPerfil: string;
  vCssVar4: string = environment.config.style.varCSS4;
  vImgEmp: string = environment.config.style.logoEmpresa;
  isImgAvatar: string;

  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, public firebase: FirebaseProvider,
    private router: Router, private alertCtrl: AlertController, private iab: InAppBrowser, public actionSheetController: ActionSheetController,
    public modalController: ModalController) {
    this.initializeApp();
    this.backButtonEvent();

    this.firebase.getRetPage().subscribe((data) => {
      this.aux = data;
    });

    this.firebase.vMenu.subscribe(m => {
      this.pages = m;
    });

    this.firebase.userLogged.subscribe(u => {
      this.funcionarioLogado = u;
      if (this.funcionarioLogado) {
        this.vComPerfil = this.funcionarioLogado.comPerfil;
        this.comFotoPerfil = this.funcionarioLogado.comFotoPerfil;
        if (this.funcionarioLogado.imagem != null && this.funcionarioLogado.imagem != undefined) {
          this.isImgAvatar = this.funcionarioLogado.imagem;
        } else {
          this.isImgAvatar = 'https://ngsservicos.com/ngs/imagens/avatar.png';
        }
      }
    });
  }

  ngOnInit() { }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
      if (this.aux != '/login') {
        this.router.navigate([this.aux]);
      } else {
        navigator['app'].exitApp();
      }
    });
  }

  pageChangeHome() {
    this.router.navigate(['/home']);
  }

  openPage(page: Menu) {
    if (page.url != null && page.url != undefined && page.url != '') {
      if (page.title == 'Avarias') {
        if (this.platform.is('android') || this.platform.is('ios')) {
          this.router.navigate([page.url])
        } else {
          this.alerta2();
        }
      } else if (page.linkExt === 'S') {
        if (this.platform.is('ios')) {
          this.iab.create(page.url, '_system', iosoption);
        } else if (this.platform.is('android')) {
          this.iab.create(page.url, '_system');
        } else {
          window.open(page.url, '_system', 'location=yes')
        }
      } else {
        this.router.navigate([page.url]);
      }
    }
  }

  loggout() {
    this.firebase.fechaAPP();
  }

  openPerfil() {
    this.router.navigate(['/perfil']);
  }

  async alerta2() {
    const alert = await this.alertCtrl.create({
      header: 'Atenção!',
      message: 'Funcionalidade não disponível para esta plataforma.',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/home']);
          }
        }
      ]
    });
    await alert.present();
  }

  async openImg(temp: string) {
    const modal = await this.modalController.create({
      component: ImgRecPage,
      cssClass: 'modal-transparency',
      componentProps: {
        img: temp
      }
    });
    return await modal.present();
  }

}
