export class FolhaPlanilha {
    seq: string;
    data: string;
    texto: string;

    constructor(seq: string, data: string, texto: string) {
        this.seq = seq;
        this.data = data;
        this.texto = texto;
    }
}