export class ExtratoVrDetail {
    dtCredito: string;
    quant: string;    
    vlrUnit: string;
    valor: string;

    constructor(dtCredito: string, quant: string, vlrUnit: string, valor: string){
        this.dtCredito = dtCredito;
        this.quant = quant;
        this.vlrUnit = vlrUnit;
        this.valor = valor;
    }
}