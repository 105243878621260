export class Tripulacao {
    registro: string;
    nome: string;
    tipo: string;
    tripulacao: string;
    veiculo: string;
    hrI1: string;
    hrF1: string;
    hrI2: string;
    hrF2: string;
    hrI3: string;
    hrF3: string;

    constructor(registro: string, nome: string, tipo: string, tripulacao: string, veiculo: string, hrI1: string, hrF1: string,
        hrI2: string, hrF2: string, hrI3: string, hrF3: string) {
        this.registro = registro;
        this.nome = nome;
        this.tipo = tipo;
        this.tripulacao = tripulacao;
        this.veiculo = veiculo;
        this.hrI1 = hrI1;
        this.hrF1 = hrF1;
        this.hrI2 = hrI2;
        this.hrF2 = hrF2;
        this.hrI3 = hrI3;
        this.hrF3 = hrF3;
    }








}