export class EleicoesCandidatos {
    codCipa: string;
    registro: string;   
    nome: string;
    funcao: string;
    dtAdm: string;
    img: string;

    constructor(codCipa: string, registro: string, nome: string, funcao: string, dtAdm: string, img: string){
        this.codCipa = codCipa;
        this.registro = registro;
        this.nome = nome;
        this.funcao = funcao;
        this.dtAdm = dtAdm;
        this.img = img;
    }

}