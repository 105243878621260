import { EleicoesCandidatos } from './eleicoes-candidatos';

export class EleicoesCipa {
    id: string;
    ano: string;
    anoFinal: string;
    anoInicial: string;
    codCipa: string;
    data: string;
    gestao: string;
    nomeEmpresa: string;
    candidatos: Array<EleicoesCandidatos>;

    constructor(id: string, ano: string, anoFinal: string, anoInicial: string, codCipa: string, data: string, gestao: string, nomeEmpresa: string, candidatos: Array<EleicoesCandidatos>) {
        this.id = id;
        this.ano = ano;
        this.anoFinal = anoFinal;
        this.anoInicial = anoInicial;
        this.codCipa = codCipa;
        this.data = data;
        this.gestao = gestao;
        this.nomeEmpresa = nomeEmpresa;
        this.candidatos = candidatos;
    }

    static adapts(obj: any): EleicoesCipa {
        let id = obj.key;
        let ano = obj.ANO;
        let anoFinal = obj.ANO_FINAL;
        let anoInicial = obj.ANO_INICIAL;
        let codCipa = obj.CODIGO;
        let data = obj.DATA;
        let gestao = 'Gestao ' + obj.ANO_INICIAL + '/' + obj.ANO_FINAL;
        let nomeEmpresa = obj.NOME_EMPRESA;  
        let candidatos = this.adaptCandidatos(obj.Candidatos);

        return new EleicoesCipa(id, ano, anoFinal, anoInicial, codCipa, data, gestao, nomeEmpresa, candidatos);
    }

    static adaptCandidatos(data): Array<EleicoesCandidatos> {
        let list = new Array<EleicoesCandidatos>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adaptCandidato(l));
            });
        }
        return list;
    }

	static adaptCandidato(obj: any): EleicoesCandidatos {
        let codCipa = obj.CODIGO;
        let registro = obj.REGISTRO;  
        let nome = obj.NOME;
        let funcao = obj.FUNCAO;
        let dtAdm = obj.DT_ADM;
        let img = obj.IMG
    
		return new EleicoesCandidatos(codCipa, registro, nome, funcao, dtAdm, img);
	}
}