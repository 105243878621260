import { TabelasOpcoes } from './tabelas-opcoes';

export class Tabelas {
    idFirebase: string;
    tabela: string;
    descricao: string;
    opcoes: Array<TabelasOpcoes>;

    constructor(id: string, tabela: string, descricao: string, opcoes: Array<TabelasOpcoes>) {
        this.idFirebase = id;
        this.tabela = tabela;
        this.descricao = descricao;
        this.opcoes = opcoes;
    }

    static adapts(obj: any): Tabelas {
        let id = obj.KEY;
        let tabela = obj.TABELA;
        let descricao = obj.DESCRICAO
        let opcoes = this.adaptOpcoes(obj.Opcoes)


        return new Tabelas(id, tabela, descricao, opcoes);
    }

    static adaptOpcoes(data): Array<TabelasOpcoes> {
		let list = new Array<TabelasOpcoes>();
		if (data != null && data != undefined && data.length > 0) {
			data.forEach(l => {
				list.push(this.adaptsOpcao(l));
			});
		}
		return list;
	}



    static adaptsOpcao(obj: any): TabelasOpcoes {
        let codigo = obj.CODIGO;
        let descricao = obj.DESCRICAO

        return new TabelasOpcoes(codigo, descricao);
    }




}