import { trimTrailingNulls } from "@angular/compiler/src/render3/view/util";
import { ExtratoVrDetail } from "./extrato-vr-detail";

export class ExtratoVr {
    idFirebase: string;
    ano: string;
    mes: string;
    comp: string;
    vlrTotal: string;
    datas: Array<ExtratoVrDetail>;

    constructor(id: string, ano: string, mes: string, comp: string, vlrTotal: string, datas: Array<ExtratoVrDetail>) {
        this.idFirebase = id;
        this.ano = ano;
        this.mes = mes;
        this.comp = comp;
        this.vlrTotal = vlrTotal;
        this.datas = datas;
    }

    static getMES(data: string): string {
        let newDate = data;
        let nome = "";

        let weekdays = new Array(12);
        weekdays[1] = "jan";
        weekdays[2] = "fev";
        weekdays[3] = "mar";
        weekdays[4] = "abr";
        weekdays[5] = "mai";
        weekdays[6] = "jun";
        weekdays[7] = "jul";
        weekdays[8] = "ago";
        weekdays[9] = "set";
        weekdays[10] = "out";
        weekdays[11] = "nov";
        weekdays[12] = "dez";

        nome = weekdays[parseInt(newDate)];

        return nome;
    }

    static adapts(obj: any): ExtratoVr {
        let idFirebase = obj.key;
        let ano = obj.ANO;
        let mes = obj.MES;
        let comp = this.getMES(obj.MES) + '/' + obj.ANO;
        let vlrTotal = obj.VALOR;
        let datas = this.adaptDatasVr(obj.Datas);

        return new ExtratoVr(idFirebase, ano, mes, comp, vlrTotal, datas);
    }

    static adaptDatasVr(data): Array<ExtratoVrDetail> {
        let list = new Array<ExtratoVrDetail>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpData(l));
            });
        }
        return list;
    }

    static adatpData(obj: any): ExtratoVrDetail {
        let dtCredito = obj.DT_CREDITO;
        let quant = obj.QUANT;
        let vlrUnit = obj.VLR_UNIT;
        let valor = obj.VALOR;    


        return new ExtratoVrDetail(dtCredito, quant, vlrUnit, valor);
    }







}