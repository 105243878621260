export class PtoEletronico {
    idFirebase: string;
    data: string;
    hora: string;
    idMvto: string;
    origem: string;
    pis: string;
    registro: string;

    constructor(id: string, data: string, hora: string, idMvto: string, origem: string, pis: string, registro: string) {
        this.idFirebase = id;
        this.data = data;
        this.hora = hora;
        this.idMvto = idMvto;
        this.origem = origem;
        this.pis = pis;
        this.registro = registro;
    }

    static adapts(obj: any): PtoEletronico {
        let idFirebase = obj.key;
        let data = obj.data;
        let hora = obj.hora;
        let idMvto = obj.id_mvto;
        let origem = obj.origem;
        let pis = obj.pis;
        let registro = obj.registro;

        return new PtoEletronico(idFirebase, data, hora, idMvto, origem, pis, registro);
    }



}