import { environment } from 'src/environments/environment';

export class Correio {
    idFirebase: string;
    data: string;
    dataFim: string;
    idMsg: string;
    lida: string;
    msgTexto: string;
    tipoMsg: string;
    dTpMsg: string;
    comImagem: string;
    urlImagem: string;
    alerta: string;
    tituloAlerta: string;
    comLink: string;
    linkMsg: string;
    tituloMsg: string;
    keySol: string;
    cpfSol: string;

    constructor(id: string, data: string, dataFim: string, idMsg: string, lida: string, msgTexto: string, tipoMsg: string, dTpMsg: string, comImg: string, urlImg: string, alerta: string, tituloAlerta: string, comLink: string, linkMsg: string, tituloMsg: string, keySol: string, cpfSol: string) {
        this.idFirebase = id;
        this.data = data;
        this.dataFim = dataFim;
        this.idMsg = idMsg;
        this.lida = lida;
        this.msgTexto = msgTexto;
        this.tipoMsg = tipoMsg;
        this.dTpMsg = dTpMsg;
        this.comImagem = comImg;
        this.urlImagem = urlImg;
        this.alerta = alerta;
        this.tituloAlerta = tituloAlerta;
        this.comLink = comLink;
        this.linkMsg = linkMsg;
        this.tituloMsg = tituloMsg;
        this.keySol = keySol;
        this.cpfSol = cpfSol;
    }

    static adapts(obj: any): Correio {
        let idFirebase = obj.key;
        let re = "/";
        let data = obj.Data.replace(/re/gi, "-");
        let dataFim = obj.DataFim;
        let idMsg = obj.IdMensagem;
        let lida = obj.Lida;
        let msgTexto = ''
        if (obj.ComImagem == 'S') {
            msgTexto = "MENSAGEM COM IMAGEM";
        } else {
            msgTexto = obj.Mensagem;
        }
         
        let tipoMsg = obj.Tipo;
        let dTpMsg = '';
        let comImagem = obj.ComImagem;
        let urlImagem = obj.Imagem;
        let alerta = obj.Alerta;
        let tituloAlerta = obj.TituloAlerta;
        let comLink = obj.ComLink;
        let linkMsg = obj.LinkMsg;
        let tituloMsg = obj.TituloMsg;
        let keySol = obj.KeySol;
        let cpfSol = obj.CpfSol;

        if (obj.Tipo === 'G' && obj.ViaCon === 'S') {
            dTpMsg = 'Mensagem ' + environment.config.empresa.nomeEmpresa;
        } else if (obj.Tipo == 'G' && obj.ViaCon != 'S' && environment.config.empresa.emp == 2) {
            dTpMsg = 'Comunicado Interno';
        } else if (obj.Tipo == 'G' && obj.ViaCon != 'S') {
            dTpMsg = 'Comunicado Geral';
        } else if (obj.Tipo == 'I' && obj.ViaCon != 'S') {
            dTpMsg = 'Mensagem para você'
        }

        return new Correio(idFirebase, data, dataFim, idMsg, lida, msgTexto, tipoMsg, dTpMsg, comImagem, urlImagem, alerta, tituloAlerta, comLink, linkMsg, tituloMsg, keySol, cpfSol);
    }
}