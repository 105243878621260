export class ExtBhDatas {
    data: string;
    hrComp: string;
    hE: string;
    hTot: string;
    msgFolga: string;
    msgGuia: string;
    saldo: string;
    seq: string;

    constructor(data: string, hrComp: string, hE: string, hTot: string, msgFolga: string, msgGuia: string, saldo: string, seq: string) {
        this.data = data;
        this.hrComp = hrComp;
        this.hE = hE;
        this.hTot = hTot;
        this.msgFolga = msgFolga;
        this.msgGuia = msgGuia;
        this.saldo = saldo;
        this.seq = seq;
    }



}