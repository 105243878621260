export class ReconhecimentoDatas {
    id: string
    codPremio: string;
    descPremio: string;
    data: string;
    obs: string;

    constructor(id: string, codPremio: string, descPremio: string, data: string, obs: string){
        this.id = id;
        this.codPremio = codPremio;
        this.descPremio = descPremio;
        this.data = data;
        this.obs = obs;
    }

}