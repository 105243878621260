export class ConsumoCombMedias {
    seq: string;
    data: string;
    linha: string;
    nomeLinha: string;
    veiculo: string;
    media: string;

    constructor(seq: string, data: string, linha: string, nomeLinha: string, veiculo: string, media: string){
        this.seq = seq;
        this.data = data;
        this.linha = linha;
        this.nomeLinha = nomeLinha;
        this.veiculo = veiculo;
        this.media = media;
    }
}