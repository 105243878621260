export class Diretrizes{
    idFirebase: string;
    ordem: string;
    titulo: string;
    texto: string;

    constructor(id: string, ordem: string, titulo: string, texto: string){
        this.idFirebase = id;
        this.ordem = ordem;
        this.titulo = titulo;
        this.texto = texto;
    }

    static adapts(obj: any): Diretrizes {
        let id = obj.KEY;
        let ordem = obj.ORDEM;
        let titulo = obj.TITULO;
        let texto = obj.TEXTO;


        return new Diretrizes(id, ordem, titulo, texto);
    }

}