export class Parametros{
    comPerfilEdita: string;
    comPerfilFoto: string;

    constructor(comPerfilEdita: string, comPerfilFoto: string){
        this.comPerfilEdita = comPerfilEdita;
        this.comPerfilFoto = comPerfilFoto;      
    }

    static adapts(obj: any): Parametros {
       let comPerfilEdita = obj.App_Com_Perfil_Edita;
       let comPerfilFoto = obj.App_Com_Perfil_Foto;


        return new Parametros(comPerfilEdita, comPerfilFoto);
    }
}