export class ValeRefeicao {
    idFirebase: string;
    cpf: string;
    ano: string;
    mes: string;
    comp: string;
    qtde: string;
    vlrTotal: string;
    vlrUnit: string;
    dtPrev: string;

    constructor(id: string, cpf: string, ano: string, mes: string, comp: string, qtde: string, vlrTotal: string, vlrUnit: string, dtPrev: string) {
        this.idFirebase = id;
        this.cpf = cpf;
        this.ano = ano;
        this.mes = mes;
        this.comp = comp;
        this.qtde = qtde;
        this.vlrTotal = vlrTotal;
        this.vlrUnit = vlrUnit;
        this.dtPrev = dtPrev;      
    }

    static getMES(data: string): string {
        let newDate = data
        let nome = "--";

        let weekdays = new Array(12);
        weekdays[1] = "jan";
        weekdays[2] = "fev";
        weekdays[3] = "mar";
        weekdays[4] = "abr";
        weekdays[5] = "mai";
        weekdays[6] = "jun";
        weekdays[7] = "jul";
        weekdays[8] = "ago";
        weekdays[9] = "set";
        weekdays[10] = "out";
        weekdays[11] = "nov";
        weekdays[12] = "dez";

        nome = weekdays[newDate];

        return nome;
    }


    static adapts(obj: any): ValeRefeicao {
        let idFirebase = obj.key;
        let cpf = obj.CPF;
        let ano = obj.Ano;
        let mes = obj.Mes;
        let comp = this.getMES(obj.Mes) + '/' + obj.Ano;
        let qtde = obj.Quantidade;
        let vlrTotal = obj.ValorTotal;
        let vlrUnit = obj.ValorUnitario;
        let dtPrev = obj.DataPrevCred;
        

        return new ValeRefeicao (idFirebase, cpf, ano, mes, comp, qtde, vlrTotal, vlrUnit, dtPrev);
    }


}