export class Eventos {
    IDEvento: string;
    dtEvento: string;
    SeqEvento: string;
    Tipo: string;
    Assunto: string;
    DiaInteiro: string;
    HoraInicio: string;
    HoraFinal: string;
    Obs: string;

    constructor (id: string, dtEvento: string, seqEvento: string, tpEvento: string, assunto: string, diaInteiro: string, hrIni: string, hrFim: string, obs: string) {
        this.IDEvento = id;
        this.dtEvento = dtEvento;
        this.SeqEvento = seqEvento;
        this.Tipo = tpEvento;
        this.Assunto = assunto;
        this.DiaInteiro = diaInteiro;
        this.HoraInicio = hrIni;
        this.HoraFinal = hrFim;
        this.Obs = obs;

    }
}