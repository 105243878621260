import { FolhaCtas } from "./folha-contas";
import { FolhaPlanilha } from "./folha-planilha";

export class Folha {
    idFirebase: string;
    ano: string;
    mes: string;
    comp: string;
    tipo: string;
    vencimentos: string;
    descontos: string;
    liquido: string;
    salario: string;
    banco: string;
    agencia: string;
    ctaBco: string;
    fgts: string;
    baseFgts: string;
    baseInss: string;
    baseIrf: string;    
    verbas: Array<FolhaCtas>;
    msgBcoHrs: string;
    msgVr: string;
    planilha: Array<FolhaPlanilha>;
    mestre: string;
    semana: string;
    confereRecibo: string;
    conferePonto: string;
    reciboPdf: string;

    constructor(id: string, ano: string, mes: string, comp: string, tipo: string, vencimentos: string, descontos: string, liquido: string, 
        salario: string, banco: string, agencia: string, ctaBco: string, fgts: string, baseFgts: string, baseInss: string, baseIrf: string,
        verbas: Array<FolhaCtas>, msgBcoHrs: string, msgVr: string, planilha: Array<FolhaPlanilha>, mestre: string, semana: string, 
        confereRecibo: string, conferePonto: string, reciboPdf: string) {

        this.idFirebase = id;
        this.ano = ano;
        this.mes = mes;
        this.comp = comp;
        this.tipo = tipo;
        this.vencimentos = vencimentos;
        this.descontos = descontos;
        this.liquido = liquido;
        this.salario = salario;
        this.banco = banco;
        this.agencia = agencia;
        this.ctaBco = ctaBco;
        this.fgts = fgts;
        this.baseFgts = baseFgts;
        this.baseInss = baseInss;
        this.baseIrf = baseIrf;
        this.verbas = verbas;
        this.msgBcoHrs = msgBcoHrs;
        this.msgVr = msgVr;
        this.planilha = planilha;
        this.mestre =  mestre;
        this.semana = semana;
        this.confereRecibo = confereRecibo;
        this.conferePonto = conferePonto;
        this.reciboPdf = reciboPdf;

    }

    static adapts(obj: any): Folha {
        let idFirebase = obj.key;
        let ano = obj.Ano;
        let mes = obj.Mes;
        let comp = this.getMES(obj.Mes) + '/' + obj.Ano
        let tipo = obj.Tipo;
        let vencimentos = obj.Vencimentos;
        let descontos = obj.Descontos;
        let liquido = obj.Liquido;
        let salario = obj.Salario;
        let banco = obj.Pgto_Banco;
        let agencia = obj.Pgto_Agencia;
        let ctaBco = obj.Pgto_Conta;
        let fgts = obj.Fgts;
        let baseFgts = obj.Base_Fgts;
        let baseInss = obj.Base_Inss;
        let baseIrf = obj.Base_Irf;
        let verbas = this.adaptVerbas(obj.Verbas);
        let msgBcoHrs = obj.Mensagem_BcoHoras;
        let msgVr = obj.Mensagem_ValeAlimentacao;
        let planilha = this.adaptPlanilha(obj.Ponto);
        let mestre = obj.Mestre;
        let semana = obj.Sem;
        let confereRecibo = obj.Confere_Recibo;
        let conferePonto = '';
        if (obj.Confere_Ponto == null && obj.Confere_Ponto == undefined) {
            conferePonto = 'False';
        } else {
            conferePonto = obj.Confere_Ponto;
        }

        let reciboPdf = obj.Pdf_ContraCheque;


        return new Folha(idFirebase, ano, mes, comp, tipo, vencimentos, descontos, liquido, salario, banco, agencia, ctaBco, fgts, baseFgts, 
            baseInss, baseIrf, verbas, msgBcoHrs, msgVr, planilha, mestre, semana, confereRecibo, conferePonto, reciboPdf);
    }

    static getMES(data: string): string {
        let newDate = data
        let nome = "--";

        let weekdays = new Array(12);
        weekdays[1] = "jan";
        weekdays[2] = "fev";
        weekdays[3] = "mar";
        weekdays[4] = "abr";
        weekdays[5] = "mai";
        weekdays[6] = "jun";
        weekdays[7] = "jul";
        weekdays[8] = "ago";
        weekdays[9] = "set";
        weekdays[10] = "out";
        weekdays[11] = "nov";
        weekdays[12] = "dez";

        nome = weekdays[newDate];

        return nome;
    }

    static adaptVerbas(data): Array<FolhaCtas> {
        let list = new Array<FolhaCtas>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpConta(l));
            });
        }
        return list;
    }

    static adatpConta(obj: any): FolhaCtas {
        let idFirebase = obj.key;
        let conta = obj.Conta;
        let nomeConta = obj.Nome_Conta;
        let tpCta = obj.Tipo;
        let valor = obj.Valor;
        let ref = obj.Referencia;

        let vcto = '';
        let desc = '';
        if (tpCta == 'V') {
            vcto = obj.Valor
        }

        if (tpCta == 'D') {
            desc = obj.Valor
        }

        return new FolhaCtas(idFirebase, conta, nomeConta, tpCta, valor, ref, vcto, desc);
    }

    static adaptPlanilha(data): Array<FolhaPlanilha> {
        let list = new Array<FolhaPlanilha>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adaptPlanilhas(l));
            });
        }
        return list;
    }

    static adaptPlanilhas(obj: any): FolhaPlanilha {
        let seq = obj.Seq;
        let data = obj.Data;
        let texto = obj.Texto;       

        return new FolhaPlanilha(seq, data, texto);
    }
}