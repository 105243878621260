export class Colaboradores {
    registro: string;
    nome: string;
    turno: string;
    dturno: string

    constructor(registro: string, nome: string, turno: string, dturno: string) {
        this.registro = registro;
        this.nome = nome;
        this.turno = turno;
        this.dturno = dturno
    }

    static adapts(obj: any): Colaboradores {
        let registro = obj.REGISTRO;
        let nome = obj.NOME;
        let turno = obj.TURNO;
        let dturno = obj.DTURNO;
     
        return new Colaboradores(registro, nome, turno, dturno);
    }
}