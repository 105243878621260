import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.html',
  styleUrls: ['./info-page.scss'],
})
export class InfoPagePage implements OnInit {
  infoPagina: string;
  page: string;

  constructor(public navParams: NavParams, public popoverController: PopoverController) {
    this.page = navParams.get('nomePage');
    this.infoPagina = navParams.get("textoPage");
  }

  ngOnInit() { }

  onClose() {
    this.popoverController.dismiss();
  }

}
