import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMsgComponent } from '../components/error-msg/error-msg.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        ErrorMsgComponent
    ],
    exports: [
        ErrorMsgComponent,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: []
})
export class SharedModule { }