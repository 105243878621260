import { SubMenu } from './submenu';

export class Menu {
    title: string;
    url: string;
    icon: string;
    color: string;
    acesso: string;
    sobre: string;
    atalho: string;
    id: string;
    linkExt: string;
    infoExt: string;
    Menu_Filho: Array<SubMenu>

    constructor(titulo: string, url: string, icone: string, color: string, acesso: string, sobre: string, atalho: string, id: string, linkExt: string,
        infoExt: string, Menu_Filho: Array<SubMenu>) {
        this.title = titulo;
        this.url = url;
        this.icon = icone;
        this.color = color;
        this.acesso = acesso;
        this.sobre = sobre;
        this.atalho = atalho;
        this.id = id;
        this.linkExt = linkExt;
        this.infoExt = infoExt;
        this.Menu_Filho = Menu_Filho;
    }

    static adapts(obj: any): Menu {
        let title = obj.title;
        let url = obj.url;
        let icon = obj.icon;
        let color = obj.color;
        let acesso = obj.acesso;
        let sobre = obj.sobre;
        let atalho = obj.atalho;
        let id = obj.id;
        let linkExt = obj.linkExt;
        let infoExt = obj.infoExt;       
        let Menu_Filho = this.adaptSubMenus(obj.Menu_Filho)

        return new Menu(title, url, icon, color, acesso, sobre, atalho, id, linkExt, infoExt, Menu_Filho);
    }

    static adaptSubMenus(data): Array<SubMenu> {
        let list = new Array<SubMenu>();
        if (data != null && data != undefined && data.length > 0) {
            data.forEach(l => {
                list.push(this.adatpSubmenu(l));
            });
        }
        return list;
    }

    static adatpSubmenu(obj: any): SubMenu {
        let idFirebase = obj.key;
        let title = obj.title;
        let url = obj.url
        let icon = obj.icon;
        let color = obj.color;
        let acesso = obj.acesso;

        return new SubMenu(idFirebase, title, url, icon, color, acesso);
    }



}