import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage;

  constructor(public storage: Storage) { }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async get(key: string) {
    return await this._storage.get(key);
  }

  public remove(key: string) {
    this._storage?.remove(key)
  }

  async clearAll() {
    await this._storage.clear();
  }

  async keyExistence(key: string) {
    if (await this.get(key) == null) {
      return true;
    } else {
      return false;
    }
  }





}