export class Versao {
    versao: string;

    constructor(versao: string) {

        this.versao = versao;
    }

    static adapts(obj: any): Versao {

        let versao = obj.Versao_Minima;

        return new Versao(versao);
    }

}