export class ExtratoHorasDatas {
    seq: string;
    data: string;
    hrExtra: string;
    hrOciosa: string;

    constructor(seq: string, data: string, hrExtra: string, hrOciosa: string) {
        this.seq = seq;
        this.data = data;
        this.hrExtra = hrExtra;
        this.hrOciosa = hrOciosa;
    }
}